/*==================================Responsive codes goes here================================*/

/*Extra small devices (landscape phones, 360px and up)=========================================*/

// @media only screen and (min-width: 300px) and (max-width: 350px)
@media only screen and (max-width: 350px) {
    // @media only screen and (max-width: 350px) {
    /*0.0 Splash styles goes here=================================*/
    .logo-text {
        font-size: 12px;
    }
    /*1.0 Onbording styles goes here==============================*/
    /*1.1 Sign in styles goes here================================*/
    .linkedin-btn {
        width: 220px;
    }
    /*1.2 Sign up styles goes here================================*/
    /*2.0 Home styles goes here===================================*/
    /*2.1 My profile styles goes here=============================*/
    /*2.1.1 View my id styles goes here===========================*/
    /*2.2 My contacts styles goes here============================*/
    /*2.2.1 Contacts profile styles goes here=====================*/
    /*2.2.2 Add new contact from gc styles goes here===============*/
    /*2.2.3 Global contacts profile styles goes here===============*/
    /*2.4 Issue a Reward styles goes here==========================*/
    /*2.5 Accept a Reward profile styles goes here=================*/
    /*2.4.1 Preparing a reward styles goes here====================*/
    .badges-grid {
        margin-left: 5px;
    }
    .badge-item {
        min-width: 126px;
        height: 132px;
        margin: 5px;
    }
    /*2.4.1.1 Badge templates styles goes here=====================*/
    /*2.4.1.2 Generated Reward Preview styles goes here============*/
    /*2.5.1 Received Reward Preview styles goes here===============*/
    .normal-txt-black {
        padding: 0px;
    }
    .url {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        max-width: 80%;
    }
    .boxbtn-transparent,
    .boxbtn-custom {
        padding: 12px 30px 15px;
    }

    .reward-item-wrap {
        justify-content: center;
    }

    /*2.6 My Rewards styles goes here==============================*/
    .reward-item-squre {
        min-width: 200px;
        margin: 4px 3px;
        padding: 2px 2px;
        height: 175px;
        width: 55%;
    }
    .merge-btn-left,
    .merge-btn-right {
        // width: 140px;
    }
    /*2.6.1 My Reward Preview styles goes here=====================*/
    /*2.6.2 My Issued Rewards styles goes here=====================*/
    /*2.6.3 Issued Reward Preview styles goes here=================*/
    /*2.7 Trophy Case styles goes here=============================*/
    /*2.7.1 Received Reward Preview in Trophy Case styles goes here=*/
    /*2.7.2 Trophy Case - Public View styles goes here=============*/
    /*2.8.1 Verified reward styles goes here=======================*/
    /*2.9 Invite Collegues styles goes here========================*/
    .popup-wrap {
        max-width: 277px;
        // height: 188px;
    }
    .popup-body {
        // padding: 14px 0px;
    }
    .share-component {
        // margin-left: -20px;
    }
}

/*Small devices (landscape phones, 576px and up)===============================================*/

@media only screen and (min-width: 350px) and (max-width: 576px) {
    /*0.0 Splash styles goes here=================================*/
    /*1.0 Onbording styles goes here==============================*/
    /*1.1 Sign in styles goes here================================*/
    .linkedin-btn {
        width: 220px;
    }
    /*1.2 Sign up styles goes here================================*/
    /*2.0 Home styles goes here===================================*/
    /*2.1 My profile styles goes here=============================*/
    /*2.1.1 View my id styles goes here===========================*/
    /*2.2 My contacts styles goes here============================*/
    /*2.2.1 Contacts profile styles goes here=====================*/
    /*2.2.2 Add new contact from gc styles goes here==============*/
    /*2.2.3 Global contacts profile styles goes here===============*/
    /*2.4 Issue a Reward styles goes here==========================*/
    /*2.5 Accept a Reward profile styles goes here=================*/
    .main-heading {
        font-size: 12px;
    }
    /*2.4.1 Preparing a reward styles goes here====================*/
    .badges-grid {
        margin-left: 5px;
    }
    .badge-item {
        min-width: 136px;
        height: 142px;
        margin: 10px;
    }
    /*2.4.1.1 Badge templates styles goes here=====================*/
    /*2.4.1.2 Generated Reward Preview styles goes here============*/
    /*2.5.1 Received Reward Preview styles goes here===============*/
    .normal-txt-black {
        padding: 0px;
    }
    .url {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        max-width: 90%;
    }
    .boxbtn-transparent,
    .boxbtn-custom {
        padding: 12px 30px 15px;
    }

    .reward-item-wrap {
        justify-content: center;
    }

    /*2.6 My Rewards styles goes here==============================*/
    .reward-item-squre {
        width: 46%;
        min-width: 217px;
        margin: 13px 8px;
        padding: 2px 2px;
        height: 185px;
    }
    .merge-btn-left,
    .merge-btn-right {
        //width: 150px;
    }
    /*2.6.1 My Reward Preview styles goes here=====================*/
    /*2.6.2 My Issued Rewards styles goes here=====================*/
    /*2.6.3 Issued Reward Preview styles goes here=================*/
    /*2.7 Trophy Case styles goes here=============================*/
    /*2.7.1 Received Reward Preview in Trophy Case styles goes here=*/
    /*2.7.2 Trophy Case - Public View styles goes here=============*/
    /*2.8.1 Verified reward styles goes here=======================*/
    /*2.9 Invite Collegues styles goes here========================*/
    .popup-wrap {
        max-width: 307px;
        // height: 208px;
    }
    .popup-body {
        // padding: 30px 0px;
    }
    /*Certificate styles goes here========================*/
    .heading-text {
        font-size: 25px;
    }
    .certificate-header-txt {
        text-align: center;
        font-size: 8px;
        padding: 0px 20px;
    }
    .certificate-section-one {
        margin: 15px 25px;
    }
    .certificate-section-two,
    .certificate-section-three,
    .certificate-section-four {
        margin-bottom: 15px;
    }
    .certificate-container h1 {
        font-size: 18px;
    }
    .certificate-container h3 {
        font-size: 16px;
    }
    .certificate-container h4 {
        font-size: 14px;
    }
    .certificate-container h6 {
        font-size: 10px;
    }
    .certificate-logo {
        width: 50px;
        height: auto;
        margin-bottom: 15px;
    }
    .certificate-logo-footer {
        width: 50px;
        height: auto;
        margin-bottom: 40px;
    }
    .certificate-frame {
        width: 85%;
    }
    .share-component {
        // margin-left: -20px;
    }
    .certificate-container {
        min-height: 430px;
        /*newly added*/
    }
    .certificate-signature img {
        width: 45px;
    }
}

/*Small devices (landscape phones, 576px and up)===============================================*/

@media only screen and (min-width: 576px) and (max-width: 768px) {
    /*0.0 Splash styles goes here=================================*/
    /*1.0 Onbording styles goes here==============================*/
    /*1.1 Sign in styles goes here================================*/
    /*1.2 Sign up styles goes here================================*/
    /*2.0 Home styles goes here===================================*/
    /*2.1 My profile styles goes here=============================*/
    /*2.1.1 View my id styles goes here===========================*/
    /*2.2 My contacts styles goes here============================*/
    /*2.2.1 Contacts profile styles goes here=====================*/
    /*2.2.2 Add new contact from gc styles goes here==============*/
    /*2.2.3 Global contacts profile styles goes here===============*/
    /*2.4 Issue a Reward styles goes here==========================*/
    /*2.5 Accept a Reward profile styles goes here=================*/
    /*2.4.1 Preparing a reward styles goes here====================*/
    /*2.4.1.1 Badge templates styles goes here=====================*/
    /*2.4.1.2 Generated Reward Preview styles goes here============*/
    /*2.5.1 Received Reward Preview styles goes here===============*/
    /*2.6 My Rewards styles goes here==============================*/
    /*2.6.1 My Reward Preview styles goes here=====================*/
    /*2.6.2 My Issued Rewards styles goes here=====================*/
    /*2.6.3 Issued Reward Preview styles goes here=================*/
    /*2.7 Trophy Case styles goes here=============================*/
    /*2.7.1 Received Reward Preview in Trophy Case styles goes here=*/
    /*2.7.2 Trophy Case - Public View styles goes here=============*/
    /*2.8.1 Verified reward styles goes here=======================*/
    /*2.9 Invite Collegues styles goes here========================*/
    .copy-input-container input {
        min-width: 315px;
    }
    .share-component {
        // margin-left: -20px;
    }
}

/*Medium devices (tablets, 768px and up)=======================================================*/

@media only screen and (min-width: 768px) and (max-width: 992px) {
    /*0.0 Splash styles goes here=================================*/
    .logo-area img {
        width: 314px;
    }
    .logo-text {
        font-size: 20px;
    }
    .copyright-text {
        font-size: 11px;
        bottom: 60px;
    }
    /*1.0 Onbording styles goes here===============================*/
    .logo img {
        width: 242px;
    }
    /*1.1 Sign in styles goes here================================*/
    /*1.2 Sign up styles goes here================================*/
    /*2.0 Home styles goes here===================================*/
    /*2.1 My profile styles goes here=============================*/
    /*2.1.1 View my id styles goes here===========================*/
    /*2.2 My contacts styles goes here============================*/
    /*2.2.1 Contacts profile styles goes here=====================*/
    /* .wrap-full {
        height: 100% !important;
    } */
    /*2.2.2 Add new contact from gc styles goes here==============*/
    /*2.2.3 Global contacts profile styles goes here==============*/
    /*2.4 Issue a Reward styles goes here==========================*/
    /*2.5 Accept a Reward profile styles goes here=================*/
    /*2.4.1 Preparing a reward styles goes here====================*/
    .badges-grid {
        margin-left: 25px;
    }
    /*2.4.1.1 Badge templates styles goes here=====================*/
    /*2.4.1.2 Generated Reward Preview styles goes here============*/
    /*2.5.1 Received Reward Preview styles goes here===============*/
    /*2.6 My Rewards styles goes here==============================*/
    /*2.6.1 My Reward Preview styles goes here=====================*/
    /*2.6.2 My Issued Rewards styles goes here=====================*/
    /*2.6.3 Issued Reward Preview styles goes here=================*/
    /*2.7 Trophy Case styles goes here=============================*/
    /*2.7.1 Received Reward Preview in Trophy Case styles goes here=*/
    /*2.7.2 Trophy Case - Public View styles goes here=============*/
    /*2.8.1 Verified reward styles goes here=======================*/
    /*2.9 Invite Collegues styles goes here========================*/
    .copy-input-container input {
        min-width: 315px;
    }
    .share-component {
        // bottom: 150px;
        // margin-left: -20px;
    }
}

/*Large devices (desktops, 992px and up)=======================================================*/

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    /*0.0 Splash styles goes here=================================*/
    /*1.0 Onbording styles goes here==============================*/
    /*1.1 Sign in styles goes here================================*/
    /*1.2 Sign up styles goes here================================*/
    /*2.0 Home styles goes here===================================*/
    /*2.1 My profile styles goes here=============================*/
    /*2.1.1 View my id styles goes here===========================*/
    /*2.2 My contacts styles goes here============================*/
    /*2.2.1 Contacts profile styles goes here=====================*/
    /* .wrap-full {
        height: 100% !important;
    } */
    /*2.2.2 Add new contact from gc styles goes here==============*/
    /*2.2.3 Global contacts profile styles goes here===============*/
    /*2.4 Issue a Reward styles goes here==========================*/
    /*2.5 Accept a Reward profile styles goes here=================*/
    /*2.4.1 Preparing a reward styles goes here====================*/
    /*2.4.1.1 Badge templates styles goes here=====================*/
    /*2.4.1.2 Generated Reward Preview styles goes here============*/
    /*2.5.1 Received Reward Preview styles goes here===============*/
    /*2.6 My Rewards styles goes here==============================*/
    /*2.6.1 My Reward Preview styles goes here=====================*/
    /*2.6.2 My Issued Rewards styles goes here=====================*/
    /*2.6.3 Issued Reward Preview styles goes here=================*/
    /*2.7 Trophy Case styles goes here=============================*/
    /*2.7.1 Received Reward Preview in Trophy Case styles goes here=*/
    /*2.7.2 Trophy Case - Public View styles goes here=============*/
    /*2.8.1 Verified reward styles goes here=======================*/
    /*2.9 Invite Collegues styles goes here========================*/
    .copy-input-container input {
        min-width: 315px;
    }
    .share-component {
        // margin-left: -20px;
    }
}

/*Extra large devices (large desktops, 1200px and up)==========================================*/

@media (min-width: 1200px) {
    /*0.0 Splash styles goes here=================================*/
    /*1.0 Onbording styles goes here==============================*/
    /*1.1 Sign in styles goes here================================*/
    /*1.2 Sign up styles goes here================================*/
    /*2.0 Home styles goes here===================================*/
    /*2.1 My profile styles goes here=============================*/
    /*2.1.1 View my id styles goes here===========================*/
    /*2.2 My contacts styles goes here============================*/
    /*2.2.1 Contacts profile styles goes here=====================*/
    /*2.2.2 Add new contact from gc styles goes here==============*/
    /*2.2.3 Global contacts profile styles goes here===============*/
    /*2.4 Issue a Reward styles goes here==========================*/
    /*2.5 Accept a Reward profile styles goes here=================*/
    /*2.4.1 Preparing a reward styles goes here====================*/
    /*2.4.1.1 Badge templates styles goes here=====================*/
    /*2.4.1.2 Generated Reward Preview styles goes here============*/
    /*2.5.1 Received Reward Preview styles goes here===============*/
    /*2.6 My Rewards styles goes here==============================*/
    /*2.6.1 My Reward Preview styles goes here=====================*/
    /*2.6.2 My Issued Rewards styles goes here=====================*/
    /*2.6.3 Issued Reward Preview styles goes here=================*/
    /*2.7 Trophy Case styles goes here=============================*/
    /*2.7.1 Received Reward Preview in Trophy Case styles goes here=*/
    /*2.7.2 Trophy Case - Public View styles goes here=============*/
    /*2.8.1 Verified reward styles goes here=======================*/
    /*2.9 Invite Collegues styles goes here========================*/
    .copy-input-container input {
        min-width: 315px;
    }
    .share-component {
        // margin-left: -20px;
    }
}

@media screen and (max-width: 1200px) {
    .bottom-nav-container {
        .wrap-full {
            min-height: 77vh;

            &.with-min-height {
                min-height: 98%;
            }
        }
    }
}
