.share-popup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: absolute;
    z-index: 1000000;
    background-color: #f8f8ff;
    bottom: 0;
    padding: 22px 30px 100px 30px;
    box-sizing: border-box;
    border-radius: 18px;

    .top-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .close-button {
        width: 36px;
        height: 36px;
        border-radius: 50px;
        background-color: #fff;
        outline: none !important;
        border: none !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 40%;
        }
    }

    .selected-post {
        width: 100%;
        border-radius: 10px;
    }

    .note {
        font-weight: 500;
    }

    .posts-wrap {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #e0e0e0;
    }

    .posts {
        display: flex;
        justify-content: flex-start;
        gap: 16px;
        overflow-x: auto;

        .post-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            box-shadow: 2px 2px 11.699999809265137px 0px #0000001a;
            border-radius: 10px;
            width: 60px;
            height: 60px;
            cursor: pointer;

            .progress {
                position: absolute;
                color: #fff !important;
                z-index: 1;
            }

            &::after {
                position: absolute;
                content: '';
                background-color: #00000066;
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }

            &[data-selected='true'] {
                &::after {
                    display: none;
                }

                .done-icon {
                    display: block;
                }
            }

            .done-icon {
                display: none;
                position: absolute;
            }

            .post {
                width: 60px;
                height: 60px;
                object-fit: contain;
                border-radius: 10px;
            }
        }
    }

    .social-media-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        padding-bottom: 20px;

        .social-wrap {
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;
            cursor: pointer;

            .icon-name {
                font-size: 0.6875em;
                font-weight: 500;
            }

            .social-menu {
                display: none;
                position: absolute;
                background-color: #fff;
                box-shadow: 2px 2px 7.199999809265137px 0px #0000001a;
                width: max-content;
                z-index: 1;
                border-radius: 10px;
                flex-direction: column;
                bottom: 80px;

                &[data-open='true'] {
                    display: flex;
                }

                .item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 16px;
                    cursor: pointer;

                    * {
                        user-select: none;
                    }

                    .item-icon {
                        fill: #24c3b5;
                    }
                }
            }
        }
    }

    .tool-tip {
        position: absolute;
        right: -15px;
        bottom: 0;
        z-index: 100;
        padding: 6px 12px;
        background-color: #3a3a3a;
        border-radius: 4px;
        color: #fff;
        border-color: none;
        font-family: 'poppins';
        font-size: 10px;

        &::before {
            content: '';
            position: absolute;
            top: -11px;
            right: 33px;
            border: 6px solid;
            border-color: transparent transparent #3a3a3a transparent;
        }
    }
}
