* {
    font-family: 'Poppins', sans-serif !important;
}

body {
    margin: 0;
    background-color: #24c3b5;
}

@media (max-width: 600px) {
    body {
        background-color: #f1f1fa;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
