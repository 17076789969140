.home-container {
    background: url(../Assets/background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px -30px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    padding-bottom: 100px;
    box-sizing: border-box;
    min-height: 100vh;

    .top-navbar {
        width: 100%;
    }

    .nav_left {
        display: flex;
        align-items: center;
        list-style-type: none;
        position: absolute;
        left: 0;
        top: 0;
        margin: 20px 30px;
        z-index: 200;

        .logo-image {
            max-width: 100px;
        }
    }

    .loading-text {
        color: #fff;
        font-weight: 700;
    }

    .rewards-cards-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 20px;
        padding-top: 120px;
        width: 100%;

        &::-webkit-scrollbar {
            width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #7cdbd3;
            border-radius: 5px;
            cursor: grab;
            transition: all 0.2s ease;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #64e9de;
        }

        .reward-card {
            display: flex;
            flex-direction: column;
            gap: 15px;
            background-color: #fff;
            padding: 15px;
            border-radius: 10px;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;

            &.pending {
                position: relative;
                background-color: #fff;
                overflow: hidden;
                color: #414141;

                .top-content {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    z-index: 2;

                    .wrapper {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        background-color: #ffebdf;
                        padding: 2px 6px;
                        border-radius: 5px;
                    }

                    .icon {
                        width: 20px;
                        height: 20px;
                        color: #ff6c17;
                    }

                    .title {
                        font-weight: 500;
                        color: #ff6c17;
                    }
                }

                .note {
                    z-index: 2;
                }
            }

            .new-reward {
                display: flex;
                gap: 4px;
                align-items: center;
                color: #24c3b5;
                font-size: 8px;
                font-weight: 500;

                &::before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #24c3b5;
                }
            }

            .details-wrap {
                display: flex;
                // flex-wrap: wrap;
                font-weight: 400;
                align-items: center;

                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                    max-width: 350px;
                }

                .issue-date,
                .desc {
                    color: #757575;
                }

                .title {
                    font-weight: 700;
                    color: #161719;
                }
            }

            .issuer-logo {
                height: 60px;
                width: 60px;
                object-fit: fill;
                border-radius: 10px;
            }

            .buttons {
                padding-top: 15px;
                border-top: 1px solid #f0e9e9;
                display: flex;
                gap: 15px;

                .button {
                    border: 1px solid #24c3b5;
                    padding: 8px 10px 8px 10px;
                    border-radius: 4px;
                    color: #fff;
                    background-color: #24c3b5;
                    font-weight: 500;
                    width: 100%;
                    cursor: pointer;

                    &.decline-button {
                        background-color: #fff;
                        color: #24c3b5;
                    }
                }
            }
        }
    }

    .wrap {
        gap: 25px;
        padding-bottom: 40px;

        * {
            margin: 0 !important;
        }
    }

    .card {
        background-color: #fff;
        box-shadow: 1px 1px 9.3px 0px #00000021;
        border-radius: 10px;
        width: 100%;
        cursor: pointer;

        &.guide {
            color: #6f6f6f;
        }

        .content {
            display: flex;
            padding: 18px 15px 12px 15px;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 15px;

                .org-logo {
                    max-height: 50px;
                    object-fit: contain;
                    width: fit-content;
                }

                .event-details {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .other-info {
                    display: flex;
                    gap: 5px;
                    color: #757575;
                }
            }

            .right {
                .event-logo {
                    max-height: 64px;
                    object-fit: contain;
                    width: fit-content;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 0 0 10px 10px;
            background-color: #f1f1f1;
            display: flex;
            padding: 12px 15px 12px 15px;

            .highlight {
                color: #464646;
            }
        }
    }
}

@media screen and (max-width: 470px) {
    .home-container {
        .rewards-cards-container {
            .reward-card {
                .buttons {
                    flex-direction: column;
                }
            }
        }
    }
}
