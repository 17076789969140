.bottom-navbar {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 24px;
    background-color: #fff;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    height: 100px;
    width: 100%;
    max-width: 550px;
    z-index: 99;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    a {
        text-decoration: none;
    }

    .icon-wrap {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        color: #e1dddd;

        .icon-box,
        .icon,
        .text {
            transition: all 0.3s ease;
        }

        &[data-active='true'] {
            color: #24c3b5;
            fill: #24c3b5;

            .icon-box {
                background-color: #24c3b533;
            }
        }

        .icon-box {
            width: 25px;
            height: 25px;
            padding: 10px;
            border-radius: 12px;

            .icon {
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (max-width: 570px) {
    .bottom-navbar {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .icon-wrap {
            font-size: 0.8em;

            .icon-box {
                width: max-content;
                height: max-content;

                .icon {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
