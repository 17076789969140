.signup-container {
    background: url(../Assets/background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px -30px;
    min-height: 100vh;

    .logo {
        img {
            margin: 0;
        }
    }

    .wrap {
        gap: 25px;
        padding-bottom: 40px;

        * {
            margin: 0 !important;
        }
    }

    .wrap {
        gap: 12px;
        padding-bottom: 40px;

        * {
            margin: 0 !important;
            font-weight: 500;
        }

        .onboard-title {
            font-weight: 600;
            margin-bottom: 25px !important;
            font-size: 1.7em;
        }
    }

    .content-section {
        width: 100%;
        background-color: #fff;
        border: 1px solid #e3e2e2;
        padding: 15px;
        border-radius: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 15px;
        text-align: center;

        .form-style {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .normal-input {
                &:nth-child(1) {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &:nth-child(2) {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }
}
