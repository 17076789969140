.element-btx-section {
    gap: 10px !important;

    button {
        width: 100% !important;
        margin: 0 !important;
    }
}

.elements-qr-code {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.elements-hide-checkbox {
    margin-top: 20px;
}

.elements-threejs-model {
    width: 100%;
    height: 70vw;
    min-height: 450px;
    max-height: 650px;
}

.threejs-model-wrap {
    position: relative;
    overflow: hidden;
}
